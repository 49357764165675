.menu {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.3rem;
}

.menu ul {
    list-style: none;
}

.menu ul li {
    padding: 1rem;
    background: #ececec;
    border-radius: 7px;
    margin: 1rem;
}

.menu ul li a{
    text-decoration: none;
    color: #242424;
}